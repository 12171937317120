<template>
  <ion-page>
    <Header back-to="/" />
    <ion-content :fullscreen="true">
      <div class="container">
        <div class="flex col-center">
          <h2 class="title">Verifikasi Email</h2>
          <div class="text-center text-sm">Masukan email akun anda.</div>
        </div>
        <div class="alert" v-if="success">
          <div>Link verifikasi berhasil dikirim. silahkan check email anda.</div>
        </div>
        <div class="form mt-2">
          <ion-input class="input" v-model="email"></ion-input>
          <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="send">Kirim</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInput, IonButton, toastController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    Header
  },
  data () {
    return {
      email: null
    }
  },
  methods: {
    async send () {
      let resp = await this.$api.post('/api/email/send-link', {
        email: this.email
      })

      if(! resp.error) {
        toastController
        .create({
          message: 'Link verifikasi berhasil dikirim. silahkan check email anda.',
          duration: 2000,
          color: 'success'
        }).then(r => r.present())
        this.$router.push('/')
      }
    }
  },
  mounted() {
    this.email = this.$auth ? this.$auth.email : null;
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
 });
</script>

<style>

</style>
